export default {
  "includes-components-main-header-navigation-menu": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/components/main-header-navigation-menu.vue").then(m => m.default || m),
  "includes-components-main-header-navigation": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/components/main-header-navigation.vue").then(m => m.default || m),
  "includes-components-main-header-user-menu": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/components/main-header-user-menu.vue").then(m => m.default || m),
  "includes-components-main-header": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/components/main-header.vue").then(m => m.default || m),
  "includes-composables-main-header-navigation-hook": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/composables/main-header-navigation.hook.ts").then(m => m.default || m),
  "includes-composables-news-banner-hook": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/composables/news-banner.hook.ts").then(m => m.default || m),
  "includes-launch-banner": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/launch-banner.vue").then(m => m.default || m),
  "includes-main-footer": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/main-footer.vue").then(m => m.default || m),
  "includes-main-header-model": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/main-header.model.ts").then(m => m.default || m),
  "includes-news-banner": () => import("/opt/render/project/src/apps/website/client/layouts/_includes/news-banner.vue").then(m => m.default || m),
  "act-for-impact": () => import("/opt/render/project/src/apps/website/client/layouts/act-for-impact.vue").then(m => m.default || m),
  default: () => import("/opt/render/project/src/apps/website/client/layouts/default.vue").then(m => m.default || m),
  document: () => import("/opt/render/project/src/apps/website/client/layouts/document.vue").then(m => m.default || m),
  login: () => import("/opt/render/project/src/packages/ui-library/client/layouts/login.vue").then(m => m.default || m)
}