/*
The following type defines our tracking plan with:

- keys as event names
- values as associated payload

 */

import type { GrowthContact } from "~/business-areas/growth/composables/growth-contact.hook";
import type { PaymentMode } from "~/business-areas/payment/payment.model";
import type { AuthenticationProvider } from "~/core/authentication/composables/authentication-signin.hook";

export type NewsletterSubscriptionOrigin =
  | "home_join_pollen_banner"
  | "course_page"
  | "main_navigation"
  | "search_page"
  | "contact_flow";

export type AnalyticsCourseView = {
  id: number;
  slug: string;
  tags: string[];
  main_tag: string;
};

export type CallRequestOrigin =
  | "course"
  | "home"
  | "getting_financed"
  | "malt_partnership"
  | "enrolment_flow"
  | "enterprise"
  | "bespoke"
  | "trainer";

export type TagListOrigin = "home_page";

export type CourseListOrigin =
  | "home_course_videos"
  | "home_pinned_courses"
  | "home_hero"
  | "home_course_list"
  | "search"
  | "course_page_suggestions"
  | "course_enrolment_success_suggestions"
  | "error_page"
  | "malt_partnership"
  | "act_for_impact_landing"
  | "certificate"
  | "enterprise";

export type TrackingPlanModel = {
  newsletter_subscription_created: {
    origin: NewsletterSubscriptionOrigin;
    email: string;
  };
  enterprise_contact_form_submitted: GrowthContact;
  search_requested: {
    tags?: string[];
  };
  search_reset: undefined;
  course_list_clicked: {
    origin:
      | CourseListOrigin
      | TagListOrigin
      | ("main_navigation" | "home_page");
    tags?: string[];
  };
  course_clicked: {
    origin: CourseListOrigin;
    course: AnalyticsCourseView;
  };
  call_flow_started: {
    origin: CallRequestOrigin;
  };
  call_requested: {
    origin: CallRequestOrigin;
  };
  call_scheduled: {
    origin: CallRequestOrigin;
  };
  search_topic_proposal_form_clicked: undefined;
  become_trainer_form_clicked: undefined;
  course_video_played: undefined;
  course_video_watched: { progress_in_percent: 25 | 50 | 75 };
  course_description_unfolded: undefined;
  course_share_link_clicked: undefined;
  course_program_publication_followed: undefined;
  course_session_publication_followed: undefined;
  user_signed_in: { provider: AuthenticationProvider; email: string };
  user_signed_up: { provider: AuthenticationProvider; email: string };
  checkout_started: undefined;
  checkout_page_displayed: undefined;
  checkout_cancelled: undefined;
  checkout_account_created: { provider: AuthenticationProvider };
  checkout_learner_info_completed: undefined;
  checkout_package_selected: {
    package_type: "BASIC" | "PREMIUM" | "PRIVATE";
  };
  checkout_billing_info_completed: undefined;
  checkout_cgu_accepted: undefined;
  checkout_payment_started: {
    payment_mode: PaymentMode;
    checkout_session_url?: string;
  };
  checkout_payment_succeeded: {
    payment_id: string;
    payment_mode: DatabaseEnum<"payment_mode">;
    transaction_value: DatabaseCompositeType<"money_amount">;
    discount?: number;
    course: AnalyticsCourseView;
  };
  checkout_card_payment_completed: undefined;
  checkout_transfer_instructions_viewed: undefined;
  faq_item_read: { title: string };
  place_dialog_viewed: undefined;
  place_maps_link_clicked: undefined;
  registration_request_created: { course: AnalyticsCourseView };
  enrolment_started: undefined;
  enrolment_mailto_clicked: undefined;
  course_details_email_form_viewed: undefined;
  course_details_email_form_submitted: undefined;
  certificate_download_clicked: undefined;
  certificate_add_to_linkedin_clicked: undefined;
  news_banner_viewed: NewsInstance;
  news_banner_clicked: NewsInstance;
  news_banner_closed: NewsInstance;

  ob_started: { origin: "automatic" | "main_navigation" | "registration" };
  ob_cancelled: undefined;
  ob_personal_information_submitted: undefined;
  ob_persona_selected: { personas: DatabaseEnum<"user_persona">[] };
  ob_interests_categories_selected: { values: DatabaseTable<"tags">["slug"][] };
  ob_interests_subcategories_selected: {
    values: DatabaseTable<"tags">["slug"][];
  };
  ob_additional_information_submitted: { value?: string };
  ob_completed: undefined;

  teacher_clicked: {
    teacher: {
      id: DatabaseTable<"teachers">["id"];
      full_name: string;
      slug: string;
    };
  };

  registration_link_viewed: undefined;
  registration_link_used: undefined;
};

type NewsInstance = {
  id: string;
  title: string;
  message: string;
};

export const toAnalyticsCourseView = ({
  id,
  slug,
  tags,
}: Pick<DatabaseTable<"courses">, "id" | "slug"> & {
  tags: { order: number; value: Pick<DatabaseTable<"tags">, "slug"> }[];
}): AnalyticsCourseView => {
  return {
    id,
    slug,
    tags: tags.map((tag) => tag.value.slug),
    main_tag: tags[0]?.value.slug ?? "",
  };
};
