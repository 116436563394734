<template>
  <app-button
    :block="block"
    :color="color"
    :size="size"
    :to="{ name: 'search', query: searchFilters }"
    :variant="variant"
    @click="onClick"
  >
    <slot>
      {{ $t("app.cta.view_all_courses") }}
    </slot>
  </app-button>
</template>

<script lang="ts" setup>
import type {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from "@pollen/ui-library/client/shared/button/button.model";

import type { TrackingPlanModel } from "~/business-areas/analytics/analytics.model";

const properties = defineProps<{
  origin: TrackingPlanModel["course_list_clicked"]["origin"];
  searchFilters?: {
    tags?: string[];
  };
  color?: ButtonColor;
  size?: ButtonSize;
  variant?: ButtonVariant;
  block?: boolean;
}>();

const { $analytics } = useNuxtApp();

const onClick = () =>
  $analytics.track("course_list_clicked", { origin: properties.origin });
</script>
