import { default as about_45usVifFxsGIq5Meta } from "/opt/render/project/src/apps/website/client/pages/about-us.vue?macro=true";
import { default as satisfaction_45form_45callback7BTs9E2X4bMeta } from "/opt/render/project/src/apps/website/client/pages/account/satisfaction-form-callback.vue?macro=true";
import { default as callbackBgCfsOWKkHMeta } from "/opt/render/project/src/apps/website/client/pages/auth/callback.vue?macro=true";
import { default as logina3Q4lkqyMUMeta } from "/opt/render/project/src/apps/website/client/pages/auth/login.vue?macro=true";
import { default as become_45trainerFj3Rqc2cz6Meta } from "/opt/render/project/src/apps/website/client/pages/become-trainer.vue?macro=true";
import { default as indexjP2TxWSSZ3Meta } from "/opt/render/project/src/apps/website/client/pages/bespoke/index.vue?macro=true";
import { default as _91id_93getl3Md9cPMeta } from "/opt/render/project/src/apps/website/client/pages/blog/[id].vue?macro=true";
import { default as indexfiHTv4W8XzMeta } from "/opt/render/project/src/apps/website/client/pages/blog/index.vue?macro=true";
import { default as _91slug_93ynMJXF9GZeMeta } from "/opt/render/project/src/apps/website/client/pages/categories/[slug].vue?macro=true";
import { default as _91certificateId_93lZSwvzsBPwMeta } from "/opt/render/project/src/apps/website/client/pages/certificates/[certificateId].vue?macro=true";
import { default as _91_46_46_46slug_93KakC9KXSUQMeta } from "/opt/render/project/src/apps/website/client/pages/content/[...slug].vue?macro=true";
import { default as indexYiVZBnecooMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/index.vue?macro=true";
import { default as errorQrJVY4UvQIMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/error.vue?macro=true";
import { default as funding_45instructionsoQoKdvYFuSMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/funding-instructions.vue?macro=true";
import { default as indexkFVIFWTyLjMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/index.vue?macro=true";
import { default as successp3mS2v4U7RMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/success.vue?macro=true";
import { default as indexCRiJMPbiS4Meta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index.vue?macro=true";
import { default as _91certificateId_93joU8hg7JcsMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/certificates/[certificateId].vue?macro=true";
import { default as email_45coverYhekdTLsDTMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/email-cover.vue?macro=true";
import { default as og_45imagemT1KPmlaQ2Meta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/og-image.vue?macro=true";
import { default as programuQpOCUHegvMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/program.vue?macro=true";
import { default as coversFnV0BDjUhMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/cover.vue?macro=true";
import { default as og_45imageFfI3VK6znFMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/og-image.vue?macro=true";
import { default as signatureBrWodd19dxMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/signature.vue?macro=true";
import { default as indexYiAWITOTEmMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index.vue?macro=true";
import { default as indexLBBjxUXUFxMeta } from "/opt/render/project/src/apps/website/client/pages/enterprise/index.vue?macro=true";
import { default as getting_45financed4MVsOTDzWEMeta } from "/opt/render/project/src/apps/website/client/pages/getting-financed.vue?macro=true";
import { default as indexWw8b47hCi6Meta } from "/opt/render/project/src/apps/website/client/pages/index/index.vue?macro=true";
import { default as methodologyShOCkM5fTzMeta } from "/opt/render/project/src/apps/website/client/pages/methodology.vue?macro=true";
import { default as onboardingAbxp6TKXYyMeta } from "/opt/render/project/src/apps/website/client/pages/onboarding.vue?macro=true";
import { default as act_45for_45impact7GbRTWocQnMeta } from "/opt/render/project/src/apps/website/client/pages/partnerships/act-for-impact.vue?macro=true";
import { default as maltoegu6VTvH0Meta } from "/opt/render/project/src/apps/website/client/pages/partnerships/malt.vue?macro=true";
import { default as restricted_45accessncbSPpuif6Meta } from "/opt/render/project/src/apps/website/client/pages/restricted-access.vue?macro=true";
import { default as indexWKAZgawo7HMeta } from "/opt/render/project/src/apps/website/client/pages/search/index.vue?macro=true";
import { default as indexdypZowg5GhMeta } from "/opt/render/project/src/apps/website/client/pages/trainers/[slug]/index.vue?macro=true";
import { default as index3gYRXo42YPMeta } from "/opt/render/project/src/apps/website/client/pages/trainers/index.vue?macro=true";
import { default as component_45stubSfqkfgXULsMeta } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.18.8_eslint@8.51.0_sass@1.69.4_typescript@5.3.3_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubSfqkfgXULs } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.18.8_eslint@8.51.0_sass@1.69.4_typescript@5.3.3_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-us",
    path: "/about-us",
    meta: about_45usVifFxsGIq5Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "account-satisfaction-form-callback",
    path: "/account/satisfaction-form-callback",
    meta: satisfaction_45form_45callback7BTs9E2X4bMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/account/satisfaction-form-callback.vue").then(m => m.default || m)
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackBgCfsOWKkHMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: logina3Q4lkqyMUMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "become-trainer",
    path: "/become-trainer",
    meta: become_45trainerFj3Rqc2cz6Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/become-trainer.vue").then(m => m.default || m)
  },
  {
    name: "bespoke",
    path: "/bespoke",
    meta: indexjP2TxWSSZ3Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/bespoke/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-id",
    path: "/blog/:id()",
    meta: _91id_93getl3Md9cPMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexfiHTv4W8XzMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    meta: _91slug_93ynMJXF9GZeMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "certificates-certificateId",
    path: "/certificates/:certificateId()",
    meta: _91certificateId_93lZSwvzsBPwMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/certificates/[certificateId].vue").then(m => m.default || m)
  },
  {
    name: "content-slug",
    path: "/content/:slug(.*)*",
    meta: _91_46_46_46slug_93KakC9KXSUQMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/content/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "courses-slug",
    path: "/courses/:slug()",
    meta: indexYiVZBnecooMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexCRiJMPbiS4Meta?.name,
    path: "/courses/:slug()/sessions/:sessionId()/enrol",
    meta: indexCRiJMPbiS4Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index.vue").then(m => m.default || m),
    children: [
  {
    name: "courses-slug-sessions-sessionId-enrol-index-error",
    path: "error",
    meta: errorQrJVY4UvQIMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/error.vue").then(m => m.default || m)
  },
  {
    name: "courses-slug-sessions-sessionId-enrol-index-funding-instructions",
    path: "funding-instructions",
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/funding-instructions.vue").then(m => m.default || m)
  },
  {
    name: "courses-slug-sessions-sessionId-enrol-index",
    path: "",
    meta: indexkFVIFWTyLjMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/index.vue").then(m => m.default || m)
  },
  {
    name: "courses-slug-sessions-sessionId-enrol-index-success",
    path: "success",
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/success.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexYiAWITOTEmMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index.vue").then(m => m.default || m),
    children: [
  {
    name: "documents-index-certificates-certificateId",
    path: "certificates/:certificateId()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/certificates/[certificateId].vue").then(m => m.default || m)
  },
  {
    name: "documents-index-courses-courseId-email-cover",
    path: "courses/:courseId()/email-cover",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/email-cover.vue").then(m => m.default || m)
  },
  {
    name: "documents-index-courses-courseId-og-image",
    path: "courses/:courseId()/og-image",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/og-image.vue").then(m => m.default || m)
  },
  {
    name: "documents-index-courses-courseId-program",
    path: "courses/:courseId()/program",
    meta: programuQpOCUHegvMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/program.vue").then(m => m.default || m)
  },
  {
    name: "documents-index-courses-courseId-sessions-sessionId-cover",
    path: "courses/:courseId()/sessions/:sessionId()/cover",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/cover.vue").then(m => m.default || m)
  },
  {
    name: "documents-index-courses-courseId-sessions-sessionId-og-image",
    path: "courses/:courseId()/sessions/:sessionId()/og-image",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/og-image.vue").then(m => m.default || m)
  },
  {
    name: "documents-index-courses-courseId-sessions-sessionId-signature",
    path: "courses/:courseId()/sessions/:sessionId()/signature",
    meta: signatureBrWodd19dxMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/signature.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "enterprise",
    path: "/enterprise",
    meta: indexLBBjxUXUFxMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/enterprise/index.vue").then(m => m.default || m)
  },
  {
    name: "getting-financed",
    path: "/getting-financed",
    meta: getting_45financed4MVsOTDzWEMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/getting-financed.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/apps/website/client/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "methodology",
    path: "/methodology",
    meta: methodologyShOCkM5fTzMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/methodology.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingAbxp6TKXYyMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "partnerships-act-for-impact",
    path: "/partnerships/act-for-impact",
    meta: act_45for_45impact7GbRTWocQnMeta || {},
    alias: ["/tracks/act-for-impact"],
    component: () => import("/opt/render/project/src/apps/website/client/pages/partnerships/act-for-impact.vue").then(m => m.default || m)
  },
  {
    name: "partnerships-malt",
    path: "/partnerships/malt",
    meta: maltoegu6VTvH0Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/partnerships/malt.vue").then(m => m.default || m)
  },
  {
    name: "restricted-access",
    path: "/restricted-access",
    meta: restricted_45accessncbSPpuif6Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/restricted-access.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/opt/render/project/src/apps/website/client/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "trainers-slug",
    path: "/trainers/:slug()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/trainers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "trainers",
    path: "/trainers",
    meta: index3gYRXo42YPMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/trainers/index.vue").then(m => m.default || m)
  },
  {
    path: "/content/legal-notices"
  },
  {
    path: "/content/privacy-policy"
  },
  {
    path: "/content/rules-of-procedure"
  },
  {
    path: "/content/terms-and-conditions"
  },
  {
    name: component_45stubSfqkfgXULsMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubSfqkfgXULs
  }
]