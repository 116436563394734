import type { DialogId } from "~/shared/dialog/dialog.registry";

export const dialogEventHook = {
  open: createEventHook<DialogOpenPayload>(),
  close: createEventHook<DialogClosePayload>(),
};

interface DialogOpenPayload {
  id: DialogId;
  binding?: Record<string, unknown>;
}

interface DialogClosePayload {
  id: DialogId;
  confirmed?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export interface DialogOpenOptions<T> {
  onConfirm?: (payload: T) => void;
  onCancel?: () => void;
}

export const useDialog = (id: DialogId) => {
  return {
    open<T>(binding?: Record<string, unknown>, options?: DialogOpenOptions<T>) {
      dialogEventHook.open.trigger({ id, binding });

      return new Promise<void>((resolve) => {
        const { off } = dialogEventHook.close.on((event) => {
          if (event.id !== id) return;

          off();

          event.confirmed
            ? options?.onConfirm?.(event.payload)
            : options?.onCancel?.();

          return resolve();
        });
      });
    },
    close(options?: Omit<DialogClosePayload, "id">) {
      dialogEventHook.close.trigger({
        id,
        ...options,
      });
    },
  };
};
