import type { TrackingPlanModel } from "~/business-areas/analytics/analytics.model";
import type { TagWithRelatedCoursesCount } from "~/business-areas/tag/tag.model";
import type { EnrichedUserUpdateRequest } from "~~/model/user.model";

export const ONBOARDING_WIZARD_AUTOMATIC_DISPLAY_DELAY_IN_MS = 500;

export type OnboardingWizardContext = {
  interests: TagWithRelatedCoursesCount[];
  additionalInformation: string | undefined;
};

export type PersonalInformationForm = Required<
  Pick<
    EnrichedUserUpdateRequest,
    "first_name" | "last_name" | "company_name" | "job_title"
  >
> &
  Pick<EnrichedUserUpdateRequest, "phone_number"> & {
    preferences: { newsletter_subscribed: boolean };
  };

export type OnboardingWizardEvent =
  | { type: "back" }
  | { type: "personal_information_submitted"; form: PersonalInformationForm }
  | { type: "personas_selected"; personas: DatabaseEnum<"user_persona">[] }
  | { type: "tags_selected"; tags: TagWithRelatedCoursesCount[] }
  | { type: "additional_information_provided"; value: string | undefined };

export type OnboardingWizardOptions = {
  origin: TrackingPlanModel["ob_started"]["origin"];
  onComplete?(payload: { context: OnboardingWizardContext }): void;
  personalInformationOnly?: boolean;
  showToastNotificationOnComplete?: boolean;
};
