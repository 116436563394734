<template>
  <transition
    enter-active-class="transition duration-300 ease-[cubic-bezier(0.175,0.885,0.32,1.275)]"
    enter-from-class="opacity-0 -translate-y-10"
  >
    <div
      v-show="!!innerState"
      class="fixed left-[50vw] top-[5vh] z-[9999] grid w-[min(100vw,530px)] -translate-x-1/2 place-content-center px-4"
    >
      <div
        v-bind="bind"
        class="flex items-start gap-3 rounded px-4 py-3 shadow-relief"
      >
        <app-icon v-if="innerState?.icon" :icon="innerState.icon" />

        <div :data-cy-toast-notification="innerState?.level">
          <p class="font-semibold" data-cy-title>{{ innerState?.title }}</p>
          <p v-if="innerState?.subtitle" class="text-sm" data-cy-subtitle>
            {{ innerState.subtitle }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
const { innerState, hasLevel, hide } = useToastNotification();

const bind = computed(() => {
  if (hasLevel("ERROR")) {
    return {
      class: "bg-error-950 text-error-50",
    };
  }

  if (hasLevel("SUCCESS")) {
    return {
      class: "bg-success-950 text-success-50",
    };
  }

  if (hasLevel("INFO")) {
    return {
      class: "bg-primary-950 text-primary-50",
    };
  }

  return {};
});

const { start } = useTimeout(3500, {
  controls: true,
  callback: hide,
  immediate: false,
});

watch(
  innerState,
  () => {
    if (!innerState.value) return;

    start();
  },
  { deep: true, immediate: true },
);
</script>
