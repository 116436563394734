<template>
  <app-chip
    :append-icon="adminPageLink ? 'ph:arrow-square-out' : undefined"
    :class="definition.class"
    :href="adminPageUrl"
    :icon="definition.icon"
    :size="size"
    ><span :class="{ 'sr-only': iconOnly }">{{
      definition.label
    }}</span></app-chip
  >
</template>

<script lang="ts" setup>
const properties = defineProps<{
  course: Pick<DatabaseTable<"courses">, "indexed" | "public" | "id">;
  size?: "small";
  iconOnly?: boolean;
  adminPageLink?: boolean;
}>();

const definition = computed<{ label: string; icon: string; class: string }>(
  () => {
    if (properties.course.public && properties.course.indexed) {
      return {
        label: "Published",
        icon: "ph:eye",
        class: "bg-success-100",
      };
    }

    if (properties.course.public && !properties.course.indexed) {
      return {
        label: "Non indexed",
        icon: "ph:eye-closed",
        class: "bg-deco-100",
      };
    }

    if (!properties.course.public && !properties.course.indexed) {
      return {
        label: "Archived",
        icon: "ph:lock",
        class: "bg-gray-100",
      };
    }

    return {
      label: "Private",
      icon: "ph:eye-slash",
      class: "bg-gray-100",
    };
  },
);

const adminPageUrl = computed(() => {
  if (properties.adminPageLink)
    return `/api/auth-bridge/admin?redirect=/courses/${properties.course.id}`;
});
</script>
