<template>
  <app-chip
    v-if="displayChip"
    class="bg-success-100 text-success-950"
    :size="size"
    >{{ capitalizeFirstLetter(formattedLabel) }}</app-chip
  >
</template>

<script lang="ts" setup>
import { differenceInDays, startOfDay } from "date-fns";

import { capitalizeFirstLetter } from "#imports";

const MAXIMUM_DIFF_IN_DAYS = 40;

const properties = defineProps<{
  session: DatabaseTable<"course_sessions">;
  size?: "small";
}>();

const diffInDays = computed(() => {
  return differenceInDays(
    startOfDay(new Date(properties.session.start_date as string)),
    startOfDay(new Date()),
  );
});

const displayChip = computed(() => {
  return diffInDays.value <= MAXIMUM_DIFF_IN_DAYS;
});

const formattedLabel = useI18nRelativeTime(properties.session.start_date!);
</script>
