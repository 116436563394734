import { startOfTomorrow } from "date-fns";

import type { CourseSessionWithPlaceView } from "~/business-areas/course-session/course-session.model";

export type CourseCardApiView = Pick<
  DatabaseTable<"courses">,
  | "id"
  | "slug"
  | "content_type"
  | "created_at"
  | "public"
  | "indexed"
  | "title"
  | "description"
  | "languages"
  | "format"
  | "video_url"
  | "pinned"
  | "subject"
> & {
  teacher: Pick<
    DatabaseTable<"teachers">,
    | "id"
    | "full_name"
    | "job"
    | "companies"
    | "avatar_url"
    | "slug"
    | "first_name"
  >;
  sessions: CourseSessionWithPlaceView[];
  tags: Array<{ order: number; value: DatabaseTable<"tags"> }>;
  ratings: [{ count: number; avg: number | null }];
  tracks: Pick<DatabaseTable<"tracks">, "id" | "slug" | "title">[];
};

export type CourseCardView = Omit<CourseCardApiView, "ratings"> & {
  ratings: { avg: number; count: number } | null;
};

export interface CourseFilters {
  excludeSlugs?: string[];
  tags?: DatabaseTable<"tags">["slug"][];
  ids?: number[];
  slug?: string;
  limit?: number;
  from?: string;
  to?: string;
  lang?: string;
  duration?: string;
  pinned?: boolean;
  track?: DatabaseTable<"tracks">["slug"];
  teacher?: DatabaseTable<"teachers">["id"];
  includeNonIndexed?: boolean;
}

const sortPerAscendingNextSessionStartDate = (courses: CourseCardApiView[]) => {
  return courses.sort((a, b) => {
    const leftCourseNextSessionStartDate = a.sessions[0]?.start_date;
    const rightCourseNextSessionStartDate = b.sessions[0]?.start_date;

    if (!leftCourseNextSessionStartDate && !rightCourseNextSessionStartDate)
      return 0;

    if (leftCourseNextSessionStartDate && !rightCourseNextSessionStartDate)
      return -1;

    if (!leftCourseNextSessionStartDate && rightCourseNextSessionStartDate)
      return 1;

    if (
      (leftCourseNextSessionStartDate as string) <
      (rightCourseNextSessionStartDate as string)
    )
      return -1;

    if (
      (leftCourseNextSessionStartDate as string) >
      (rightCourseNextSessionStartDate as string)
    )
      return 1;

    return 0;
  });
};

export const useCourseCardList = (
  key: string,
  filters?: CourseFilters,
  options?: { server?: boolean; lazy?: boolean },
) => {
  const client = useDatabaseClient();
  const courseCache = useCourseCache();

  return useAsyncData(
    key,
    async () => {
      const isDateFilterActive = filters?.from || filters?.to;

      let query = client
        .from("courses")
        .select(
          `id, created_at, slug, title, description, public, indexed, subject, pinned, languages, format, content_type, video_url, teacher: teachers${
            filters?.teacher ? "!inner" : ""
          }(id, first_name, full_name, job, companies, avatar_url, slug), ratings: course_session_ratings(global_satisfaction.avg(), global_satisfaction.count()), sessions: course_sessions${
            isDateFilterActive ? "!inner" : ""
          }(*, place: places(*)), tags: course_tags!inner(order, value: tags!inner(*)), tracks: tracks${
            filters?.track === undefined ? "" : "!inner"
          }(id, slug, title)`,
        )
        .eq("sessions.public", true)
        .order("start_date", { referencedTable: "course_sessions" })
        .order("order", { referencedTable: "course_tags" });

      if (!isDateFilterActive) {
        query = query.gte(
          "sessions.start_date",
          startOfTomorrow().toISOString(),
        );
      }

      if (filters?.includeNonIndexed !== true) {
        query = query.eq("indexed", true);
      }

      if (filters?.to) {
        query = query.lte("sessions.start_date", filters?.to);
      }

      if (filters?.from) {
        query = query.gte("sessions.start_date", filters?.from);
      }

      if (filters?.excludeSlugs?.length) {
        query = query.not("slug", "in", `(${filters.excludeSlugs.join(",")})`);
      }

      if (filters?.tags?.length) {
        query = query.filter(
          "tags.value.path",
          "match",
          `(${filters.tags.join("|")}).*`,
        );
      }

      if (filters?.ids) {
        query = query.in("id", filters.ids);
      }

      if (filters?.lang) {
        query = query.overlaps("languages", [filters.lang]);
      }

      if (filters?.duration) {
        query = query.eq("format->duration", `"${filters.duration}"`);
      }

      if (filters?.limit) {
        query = query.limit(filters.limit);
      }

      if (filters?.pinned) {
        query = query.eq("pinned", true);
      }

      if (filters?.slug !== undefined) {
        query = query.eq("slug", filters.slug);
      }

      if (filters?.track !== undefined) {
        query = query.eq("tracks.slug", filters.track);
      }

      if (filters?.teacher !== undefined) {
        query = query.eq("teacher.id", filters.teacher);
      }

      const { data } = await query.returns<CourseCardApiView[]>();

      return sortPerAscendingNextSessionStartDate(data ?? []);
    },
    {
      transform(data) {
        const views = data.map((apiView) => {
          const ratings = apiView.ratings[0];
          const view: CourseCardView = {
            ...apiView,
            ratings:
              ratings.avg !== null && ratings.count > 0
                ? {
                    avg: ratings.avg,
                    count: ratings.count,
                  }
                : null,
          };

          return view;
        });

        courseCache.setMultiple(views);

        return views;
      },
      default: () => [] as CourseCardView[],
      watch: filters ? Object.values(toRefs(filters)) : undefined,
      server: options?.server ?? true,
      lazy: options?.lazy,
    },
  );
};
