<template>
  <div ref="wrapper">
    <app-card-link-background :to="coursePageLink">
      <app-card
        class="group relative grid h-full max-w-[400px] grid-rows-[auto_1fr] !bg-transparent"
        data-cy-course-card
        :data-cy-course-card-id="course.id"
        tag="section"
      >
        <teacher-card
          :hovered="isHovered"
          :teacher="course.teacher"
          :video="course.video_url"
        />

        <div class="grid place-content-start gap-4 pt-4">
          <div class="flex flex-wrap gap-2 text-black">
            <course-rating-chip
              v-if="course.ratings"
              size="small"
              :total-number-of-ratings="course.ratings.count"
              :value="course.ratings.avg"
            />
            <course-fresh-chip v-else size="small" />

            <course-session-date-chip
              v-if="nextSession"
              :class="{ 'line-through': nextSession?.is_sold_out }"
              :session="nextSession"
              size="small"
            />

            <place-address-chip
              v-if="nextSession?.place"
              :address="nextSession.place.address"
              field="city"
              size="small"
            />

            <course-online-type-chip
              v-else-if="nextSession?.is_online"
              size="small"
            />
          </div>

          <h3 class="line-clamp-2 font-semibold leading-normal">
            {{ course.title }}
          </h3>
        </div>

        <div v-if="nextSession" class="absolute left-2 top-2">
          <course-session-sold-out-chip v-if="nextSession.is_sold_out" />
          <course-session-relative-start-date-chip
            v-else
            :session="nextSession"
            size="small"
          />
        </div>

        <admin-access>
          <course-visibility-chip
            class="absolute right-2 top-2"
            :course="course"
            icon-only
            size="small"
          />
        </admin-access>
      </app-card>
    </app-card-link-background>
  </div>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from "#vue-router";
import type { CourseCardView } from "~/business-areas/course/composables/course-card-list.hook";

const properties = defineProps<{
  course: CourseCardView;
}>();

const coursePageLink = computed<RouteLocationRaw>(() => {
  return {
    name: "courses-slug",
    params: {
      slug: properties.course.slug,
    },
  };
});

const nextSession = computed(() => properties.course.sessions[0]);
const wrapperElement = templateRef<HTMLElement>("wrapper");
const isHovered = useElementHover(wrapperElement);
</script>
