<template>
  <nuxt-img v-bind="bind" @error="onError" @load="onLoad" />
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    src: string;
    alt: string;
    width?: number;
    height?: number;
    loading?: "lazy" | "eager";
    provider?: string;
  }>(),
  {
    loading: "lazy",
    provider: undefined,
    width: undefined,
    height: undefined,
  },
);

const {
  public: {
    cloudinary: { baseUrl },
  },
} = useRuntimeConfig();

const resolvedProvider = computed(() =>
  properties.src.includes(baseUrl) ? "cloudinary" : undefined,
);

const bind = computed(() => {
  const src =
    resolvedProvider.value === "cloudinary"
      ? properties.src.replace(baseUrl, "")
      : properties.src;

  return {
    ...properties,
    width: properties.width,
    height: properties.height,
    provider: resolvedProvider.value,
    src,
    // Forcing srcset to null as it seems to cause loading issues with Cloudinary provider
    srcset: null,
    style: {
      "--imageHeight": properties.height ? `${properties.height}px` : undefined,
    },
    class: {
      // Force image height to ensure it's repsected in case only height is provided
      "h-[--imageHeight]":
        properties.height !== undefined && properties.width === undefined,
    },
    decoding: "auto" as "auto" | "async" | "sync" | undefined,
  };
});

const state = reactive({ loaded: false, error: false });

const onLoad = () => {
  state.loaded = true;
  state.error = false;
};

const onError = () => {
  state.loaded = false;
  state.error = true;
};
</script>
