<script lang="ts">
import { useActiveDialog } from "~/shared/dialog/composables/active-dialog.hook";
import { dialogRegistry } from "~/shared/dialog/dialog.registry";

// eslint-disable-next-line import/no-default-export
export default defineComponent({
  components: dialogRegistry,

  setup() {
    const { id: activeDialogId, binding } = useActiveDialog();

    const model = ref(false);

    watch(activeDialogId, (value) => {
      model.value = !!value;
    });

    watch(model, (value) => {
      if (!value) activeDialogId.value = undefined;
    });

    return () =>
      activeDialogId.value
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          h(dialogRegistry[activeDialogId.value], {
            ...binding.value,
            modelValue: model.value,
            "onUpdate:modelValue": (value: boolean) => (model.value = value),
          })
        : undefined;
  },
});
</script>
