import validate from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.18.8_eslint@8.51.0_sass@1.69.4_typescript@5.3.3_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45restricted_45access_45middleware_45global from "/opt/render/project/src/apps/website/client/middleware/0.restricted-access.middleware.global.ts";
import authentication_45middleware_45global from "/opt/render/project/src/apps/website/client/middleware/authentication.middleware.global.ts";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.0_@types+node@18.18.8_eslint@8.51.0_sass@1.69.4_typescript@5.3.3_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45restricted_45access_45middleware_45global,
  authentication_45middleware_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}