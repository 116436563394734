<template>
  <app-page-section :title="displayedTitle">
    <app-carousel :item-gap="1" :item-width="315" :items="list">
      <template #item="{ item }">
        <div class="h-full p-2">
          <course-card
            class="h-full"
            :course="item"
            @click="onCardClick(item)"
          />
        </div>
      </template>
    </app-carousel>

    <template #append>
      <slot name="append">
        <search-results-button
          color="dark"
          :origin="id"
          :search-filters="searchFilters"
          size="lg"
          variant="outlined"
        />
      </slot>
    </template>
  </app-page-section>
</template>

<script lang="ts" setup>
import type { TrackingPlanModel } from "~/business-areas/analytics/analytics.model";
import { toAnalyticsCourseView } from "~/business-areas/analytics/analytics.model";
import type { CourseCardView } from "~/business-areas/course/composables/course-card-list.hook";
import type { I18nMessage } from "~/core/i18n/i18n.model";

const { t } = useI18n();

const properties = defineProps<{
  title?: I18nMessage;
  list: CourseCardView[];
  id: TrackingPlanModel["course_clicked"]["origin"];
  searchFilters?: {
    tags?: string[];
  };
}>();

const displayedTitle = computed(
  () => properties.title ?? t("course.card_list.default_title"),
);

const { $analytics } = useNuxtApp();

const onCardClick = (course: CourseCardView) =>
  $analytics.track("course_clicked", {
    origin: properties.id,
    course: toAnalyticsCourseView(course),
  });
</script>
