import { v4 } from "uuid";

const FF_COOKIE_NAME = "feature_flags_identity";
const FF_COOKIE_MAX_DURATION_IN_SECONDS = 6 * 30 * 24 * 3600;

export default defineNuxtPlugin({
  enforce: "pre",
  async setup() {
    const { set } = useFeatureFlagStore();

    const identity = useCookie(FF_COOKIE_NAME, {
      maxAge: FF_COOKIE_MAX_DURATION_IN_SECONDS,
    });

    if (!identity.value) {
      identity.value = v4();
    }

    try {
      const state = await $fetch("/api/feature-flags", {
        query: {
          identity: identity.value,
        },
      });
      set(state);
    } catch {}
  },
});
