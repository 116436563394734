import { AnalyticsBrowser } from "@segment/analytics-next";
import type { MaybeRef } from "@vueuse/core";
import type { RouteLocationNormalized } from "vue-router";
export type AnalyticsTrackingPlan = Record<string, unknown | undefined>;

export type AnalyticsClient<P extends AnalyticsTrackingPlan> = {
  track<E extends keyof P, T extends P[E]>(
    ...args: T extends undefined ? [E] : [E, T]
  ): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  identify(id?: any, payload?: any): void;
};

type AnalyticsContext = Record<string, Record<string, unknown>> | null;

export const useAnalyticsContext = () => {
  const analyticsContext = useState<AnalyticsContext | null>(
    "analytics-context",
    () => null,
  );

  return {
    analyticsContext,
  };
};

export const defineAnalyticsContext = (
  context: AnalyticsContext | null,
  options?: { merge?: boolean },
) => {
  const analyticsContext = useState("analytics-context");

  if (!options?.merge) {
    analyticsContext.value = context;
    return;
  }

  analyticsContext.value = {
    // eslint-disable-next-line unicorn/no-useless-fallback-in-spread
    ...(analyticsContext.value ?? {}),
    ...context,
  };
};

export function getAnalyticsClient<P extends AnalyticsTrackingPlan>(
  apiKey?: string,
  options?: {
    globalContext?: MaybeRef<Record<string, unknown>>;
  },
): AnalyticsClient<P> {
  const { analyticsContext } = useAnalyticsContext();

  function getContext() {
    return {
      ...unref(options?.globalContext?.value ?? {}),
      ...analyticsContext.value,
    };
  }

  const analytics: AnalyticsBrowser = apiKey
    ? AnalyticsBrowser.load({ writeKey: apiKey })
    : ({
        track(...args) {
          // eslint-disable-next-line no-console
          console.info(`👀 Analytics event`, args[0], {
            ...getContext(),
            ...args[1],
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        identify: (id?: any, payload?: any) => {
          // eslint-disable-next-line no-console
          console.info(`👀 Analytics identification`, id, payload);
        },
        page(category: string, path: string, context: unknown) {
          // eslint-disable-next-line no-console
          console.info(`👀 Analytics page view`, path, context);
        },
      } as AnalyticsBrowser);

  const router = useRouter();
  const { me } = useCurrentUser();
  const { ABTests } = useFeatureFlagStore();

  const track = <E extends keyof P, T extends P[E]>(
    ...args: T extends undefined ? [E] : [E, T]
  ) => {
    analytics.track(args[0] as string, {
      email: me.value?.email,
      ABTests: ABTests.value,
      ...getContext(),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ...args[1],
    });
  };

  if (import.meta.client) {
    router.afterEach(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        analytics.page("", to.path, {
          path: to.path,
          url: to.fullPath,
          referrer: from.fullPath,
          ABTests: ABTests.value,
          ...getContext(),
        });
      },
    );

    router.beforeEach(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        if (to.name !== from.name) {
          analyticsContext.value = null;
        }
      },
    );
  }

  return {
    identify: analytics.identify,
    track,
  };
}
