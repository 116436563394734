import type {
  FeatureFlag,
  FeatureFlagKey,
  FeatureFlagRegistry,
} from "../../model/feature-flag.model";

export function useFeatureFlagStore() {
  const innerState = useState<FeatureFlagRegistry>(
    "feature_flags",
    () => ({}) as FeatureFlagRegistry,
  );

  const ABTests = computed(() => {
    return Object.entries(innerState.value).reduce(
      (registry, [key, flag]) => {
        if (flag.enabled && flag.value) {
          registry[key as FeatureFlagKey] = flag.value;
        }

        return registry;
      },
      {} as Record<FeatureFlagKey, FeatureFlag["value"]>,
    );
  });

  return {
    flags: readonly(innerState),
    ABTests,
    set: (state: FeatureFlagRegistry) => {
      innerState.value = state;
    },
  };
}

export function useFeatureFlag(key: FeatureFlagKey, value?: string) {
  const { flags } = useFeatureFlagStore();
  const isEnabled = flags.value[key]?.enabled;
  const hasRightValue = flags.value[key]?.value === value;

  return isEnabled && hasRightValue;
}
