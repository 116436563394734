import type { TranslateResult } from "vue-i18n";

import type { ToastNotificationLevel } from "../toast-notification.model";

interface ToastNotificationOptions {
  level: ToastNotificationLevel;
  title: TranslateResult;
  subtitle?: TranslateResult;
  icon?: string;
}

type ToastNotificationFactoryOptions = Omit<
  ToastNotificationOptions,
  "title" | "level"
>;

const DEFAULT_ICONS = new Map<ToastNotificationLevel, string>([
  ["ERROR", "ph:warning"],
  ["SUCCESS", "ph:confetti"],
  ["INFO", "ph:bell"],
]);

export const useToastNotification = () => {
  const innerState = useState<ToastNotificationOptions | undefined>(
    "active_toast_notification",
  );

  const hide = () => (innerState.value = undefined);

  const show = (options: ToastNotificationOptions) => {
    innerState.value = {
      ...options,
      icon: options.icon ?? DEFAULT_ICONS.get(options.level),
    };
  };

  const showSuccess = (
    title: TranslateResult,
    options?: ToastNotificationFactoryOptions,
  ) => {
    show({
      level: "SUCCESS",
      title,
      ...options,
    });
  };

  const showError = (
    title: TranslateResult,
    options?: ToastNotificationFactoryOptions,
  ) => {
    show({
      level: "ERROR",
      title,
      ...options,
    });
  };

  const showInfo = (
    title: TranslateResult,
    options?: ToastNotificationFactoryOptions,
  ) => {
    show({
      level: "INFO",
      title,
      ...options,
    });
  };

  const hasLevel = (level: ToastNotificationLevel) =>
    innerState.value?.level === level;

  return {
    innerState: readonly(innerState),
    hide,
    show,
    showSuccess,
    showError,
    showInfo,

    hasLevel,
  };
};

export function showSuccessToastNotification(
  title: TranslateResult,
  options?: ToastNotificationFactoryOptions,
) {
  return useToastNotification().show({ level: "SUCCESS", title, ...options });
}

export function showErrorToastNotification(
  title: TranslateResult,
  options?: ToastNotificationFactoryOptions,
) {
  return useToastNotification().show({ level: "ERROR", title, ...options });
}
