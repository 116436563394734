
import * as cloudinaryRuntime$v8aSm1ysHU from '/opt/render/project/src/node_modules/.pnpm/@nuxt+image@1.0.0/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'
import * as ipxRuntime$BruuFyehCm from '/opt/render/project/src/node_modules/.pnpm/@nuxt+image@1.0.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "lh3.googleusercontent.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$v8aSm1ysHU, defaults: {"baseURL":"https://res.cloudinary.com/dfdgwwkpd/image/upload/","modifiers":{"c":"fill","dpr":"3.0","q":"auto"}} },
  ['ipx']: { provider: ipxRuntime$BruuFyehCm, defaults: {} }
}
        