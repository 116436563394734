import type { RouteLocationNormalizedLoaded } from "#vue-router";
import type {
  OnboardingWizardContext,
  OnboardingWizardOptions,
} from "~/business-areas/onboarding/onboarding.model";
import { ONBOARDING_WIZARD_AUTOMATIC_DISPLAY_DELAY_IN_MS } from "~/business-areas/onboarding/onboarding.model";
import type { TagWithRelatedCoursesCount } from "~/business-areas/tag/tag.model";

export function useOnboarding() {
  const onboardingDialog = useDialog("onboardingDialog");
  const storedOnboardingAcknowledgement = useSessionStorage("onboarded", "");
  const { me } = useEnrichedCurrentUser();
  const route = useRoute();
  const { $emit } = useNuxtApp();
  const { t } = useI18n();

  function acknowledgeOnboardingForCurrentUser() {
    if (!me.value) return;

    storedOnboardingAcknowledgement.value = me.value.email;
  }

  function displayRelevantSearchResultsIfAlreadyOnSearchPage(
    tags: TagWithRelatedCoursesCount[],
  ) {
    if (route.name !== "search" || route.query.tags !== undefined) return;

    return $emit("search_requested", {
      tags: tags
        .filter((tag) => tag.depth === 0 && tag.related_courses_count > 0)
        .map((tag) => tag.slug),
    });
  }

  function start(options?: OnboardingWizardOptions) {
    if (!me.value) return;

    onboardingDialog.open<{ context: OnboardingWizardContext }>(
      {
        options,
      },
      {
        onCancel: acknowledgeOnboardingForCurrentUser,
        onConfirm(payload) {
          onboardingDialog.close();
          acknowledgeOnboardingForCurrentUser();

          if (options?.showToastNotificationOnComplete ?? true) {
            useToastNotification().showSuccess(
              t("onboarding.completed_message"),
            );
          }

          return (
            options?.onComplete?.(payload) ??
            displayRelevantSearchResultsIfAlreadyOnSearchPage(
              payload.context.interests,
            )
          );
        },
      },
    );
  }

  const hasBeenOnboarded = computed(
    () =>
      storedOnboardingAcknowledgement.value === me.value?.email ||
      !!me.value?.profile,
  );

  return { start, hasBeenOnboarded };
}

function isRouteWhiteListedForAutonomousOnboarding(
  route: RouteLocationNormalizedLoaded,
) {
  return (
    route.name &&
    !route.name.startsWith("auth") &&
    !route.name.startsWith("account") &&
    !route.name.startsWith("documents") &&
    !route.name.startsWith("courses-slug") &&
    !route.name.startsWith("trainers-slug") &&
    !!(route.meta.onboarding ?? true)
  );
}

export function useOnboardingAutonomousStart() {
  const { start, hasBeenOnboarded } = useOnboarding();
  const route = useRoute();
  const { isAuthenticated } = useCurrentUser();

  if (import.meta.client) {
    onNuxtReady(async () => {
      whenever(
        computed(
          () =>
            isAuthenticated.value &&
            isRouteWhiteListedForAutonomousOnboarding(route) &&
            !hasBeenOnboarded.value,
        ),
        async () => {
          /*
          Let's wait a bit to avoid showing a modal to user face as soon as he/she is on Pollen
           */
          await timer(ONBOARDING_WIZARD_AUTOMATIC_DISPLAY_DELAY_IN_MS);

          // avoid autonomous onboarding start if another dialog is already open
          if (!!useActiveDialog().id.value) return;

          start({ origin: "automatic" });
        },
        { immediate: true, once: true },
      );
    });
  }
}
