<template>
  <app-chip
    :append-icon="appendIcon"
    class="flex items-center gap-2 bg-deco2-50 transition"
    :class="{
      'hover:bg-deco2-100 hover:ring-2 ring-deco2-100':
        !!$attrs.onClick || !!to,
    }"
    :size="size"
    :to="to"
  >
    <app-rating-stars
      :size="size === 'small' ? 16 : undefined"
      :value="value"
    />
    <strong
      :class="{
        'text-lg': size !== 'small',
        'text-sm font-semibold': size === 'small',
      }"
      >{{ label }}</strong
    >
    <span
      v-if="totalNumberOfRatings !== undefined"
      class="text-sm font-normal"
      :class="{ 'text-xs': size === 'small' }"
      >({{ totalNumberOfRatings }})</span
    >
  </app-chip>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { RouteLocationRaw } from "#vue-router";

const properties = withDefaults(
  defineProps<{
    value: number;
    totalNumberOfRatings?: number;
    size?: "small";
    appendIcon?: string;
    to?: RouteLocationRaw;
  }>(),
  {
    appendIcon: undefined,
    tooltip: undefined,
    size: undefined,
    totalNumberOfRatings: undefined,
    to: undefined,
  },
);

const label = computed(
  () =>
    useI18nNumber(properties.value, {
      maximumFractionDigits: 1,
    }).value,
);
</script>
