import type { MaybeRef } from "@vueuse/core";

/*
Totally taken from https://css-tricks.com/converting-color-spaces-in-javascript/
 */
export function hexToHSL(
  hexa: string,
  options?: { saturation?: number; luminance?: number },
) {
  let r = 0,
    g = 0,
    b = 0;

  if (hexa.length == 4) {
    r = ("0x" + hexa[1] + hexa[1]) as unknown as number;
    g = ("0x" + hexa[2] + hexa[2]) as unknown as number;
    b = ("0x" + hexa[3] + hexa[3]) as unknown as number;
  } else if (hexa.length == 7) {
    r = ("0x" + hexa[1] + hexa[2]) as unknown as number;
    g = ("0x" + hexa[3] + hexa[4]) as unknown as number;
    b = ("0x" + hexa[5] + hexa[6]) as unknown as number;
  }

  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;

  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;

  let h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return (
    "hsl(" +
    h +
    "," +
    (options?.saturation ?? s) +
    "%," +
    (options?.luminance ?? l) +
    "%)"
  );
}

export const useStringToColor = (
  str: MaybeRef<string>,
  options: { saturation?: number; luminance?: number } = {},
) => {
  return computed(() => {
    const unwrappedString = unref(str);

    const hue =
      [...unwrappedString].reduce((_hash, _, index) => {
        _hash = unwrappedString.charCodeAt(index) + ((_hash << 5) - _hash);

        return _hash;
      }, 0) % 360;

    const saturation = options.saturation ?? 50;
    const luminance = options.luminance ?? 60;

    return `hsl(${hue}, ${saturation}%, ${luminance}%)`;
  });
};
